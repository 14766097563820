import { combineReducers } from 'redux';
import { languagesReducer, languageIdReducer, countriesReducer } from './languageReducer';
import { profileReducer } from './profileReducer';
import { i18nReducer } from './i18nReducer';
import { reducer as formReducer } from 'redux-form';
import { RootState } from '../types/state';

export const rootReducer = combineReducers ({
    form: formReducer,
    languages: languagesReducer,
    countries: countriesReducer,
    selectedLanguageId : languageIdReducer,
    profile : profileReducer,
    i18n : i18nReducer
}) as any