import { Action } from "redux";
import { AsyncAction } from "../";
import LanguageApi from "../../api/CMS/Language";
import { GetCountyAction, GetLanguageAction } from "../../types/actionTypes/languageType";
import * as languageTypes from '../../types/actionTypes/languageType'
import { Language } from "../../types/model";

export const getLanguages = (): AsyncAction<GetLanguageAction> => {
    return async (dispatch) => {
        const languages = await LanguageApi.getLanguages();
        return dispatch({ type: languageTypes.GET_LANGUAGES, languages });
    };
}

export const setLanguageId = (languageId: number): AsyncAction<any> => {
    return async (dispatch) => {
        return dispatch({ type: languageTypes.SET_LANGUAGE_ID, languageId });
    };
}

export const editLanguage = async (values: Language) => {
    return await LanguageApi.editLanguage(values);
}

export const setLocalLanguageId = (languageId: number): AsyncAction<any> => {
    return async (dispatch) => {
        return dispatch({ type: languageTypes.SET_LOCAL_LANGUAGE_ID, languageId });
    };
}

export const getCountries = (): AsyncAction<GetCountyAction> => {
    return async (dispatch) => {
        const countries = await LanguageApi.getCountries();

        return dispatch({ type: languageTypes.GET_COUNTRIES, countries });
    };
}