// TableContainer.js
import React, { useEffect, useState } from "react"

import { usePagination, Hooks, useTable, TableProps, TableOptions, useFilters } from 'react-table'
import GridviewFooterComponent from "./GridviewFooterComponent";
import { ReactComponent as BarsIcon } from '../../images/icons/bars.svg';
import { ReactComponent as RecycleBinIcon } from '../../images/icons/recycle-bin.svg';
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { DefaultColumnFilter, Filter } from "./filter/columnFilter";

const _pageSize = 100 as number;

export default function ({ columns, data, onRowClick, deleteItem, onDeleteClick, listOfTooltips, changeOrder }: any) {

  var {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    pageCount,
    gotoPage,
    state: { pageIndex }
  }
    = useTable(
      {
        defaultColumn: { Filter: DefaultColumnFilter },
        columns: columns,
        data: data,
        initialState: { pageIndex: 0, pageSize: _pageSize }
      } as any,
      useFilters as any,
      usePagination as <D extends object = {}>(hooks: Hooks<D>) => void
    ) as any;

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <table  {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any) => {
              return (
                <tr  {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => {
                    return (
                      <th {...column.getHeaderProps()}>
                        <div>
                          {column.render("Header")}
                        </div>
                        <Filter column={column} />
                      </th>
                    )
                  })}
                </tr>
              )
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row: any, index: any) => {
              prepareRow(row);
              return (
                <Row
                  index={index}
                  row={row}
                  onClick={onRowClick}
                  deleteItem={deleteItem}
                  onDeleteClick={onDeleteClick}
                  listOfTooltips={listOfTooltips}
                  changeOrder={changeOrder}
                  {...row.getRowProps()}
                />
              )
            })}
          </tbody>
        </table>
      </DndProvider>
      <GridviewFooterComponent
        amountOfResults={rows ? rows.length : 0}
        pageCount={pageCount}
        goToPage={gotoPage}
        currentPage={pageIndex + 1}
      />
    </>
  )
}

const DND_ITEM_TYPE = 'row'

const Row = ({ row, index, onClick, canChangeOrder, deleteItem, onDeleteClick, listOfTooltips, changeOrder }: any) => {

  const dropRef = React.useRef(null) as any
  const dragRef = React.useRef(null) as any

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item: any, monitor: any) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
    //drop: (item: any, monitor: any) => onDragAndDrop(monitor.getItem(), index)
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: { type: DND_ITEM_TYPE, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  })

  const opacity = isDragging ? 0 : 1

  preview(drop(dropRef))
  drag(dragRef)

  return (
    <tr ref={dropRef} className="tooltip" style={{ opacity }}>
      {row.cells.map((cell: any, i: number) => {
        return (<td onClick={() => onClick ? onClick(row) : {}} {...cell.getCellProps({ className: cell.column.className })}>
          {cell.render('Cell')}
          {listOfTooltips && i === 2 &&
            <div className="tooltip-relative"><span className="tooltiptext">{listOfTooltips[index]}</span></div>
          }
        </td>)
      })}
      {deleteItem ? <td style={{ paddingRight: '10px' }} width="20px"><RecycleBinIcon onClick={(e: any) => { onDeleteClick(false, row) }} height="25px" /></td> : <></>}
      {changeOrder ? <td><BarsIcon onClick={(e: any) => { changeOrder(row) }} height="25px" /></td> : <></>}
    </tr>
  )
}

// export default Gridview