export default {
    notFoundText: 'The page you\'re trying to access cannot be found',
    login: 'Login',
    emailAddress: 'Email Address',
    password: 'Password',
    forgotPassword: 'Forgot password',
    survey: 'Survey',
    surveys: 'Surveys',
    active: 'Active',
    archive: 'Archive',
    inActive: 'Inactive',
    all: 'All',
    ready: 'Ready',
    concept: 'Concept',
    search: 'Search',
    createSurvey: 'Create survey',
    createCampaign: 'Create Campaign',
    forgotPasswordInformation: 'Fill in your username (Email Address) and we send you a link to reset your password',
    backToLogin: 'Back to login',
    logOut: 'Log out',
    send: 'Send',
    youGotMail: 'You got mail!',
    youGotMailInformation: 'Check your mail. We had send you an mail to reset your password',
    editPassword: 'Edit password',
    newPassword: 'New Password',
    repeatPassword: 'Repeat password',
    save: 'Save',
    general: 'General',
    id: 'ID',
    number: 'Number',
    status: 'Status',
    title: 'Title',
    characteristic: 'Characteristic',
    questions: 'Questions',
    question: 'Question',
    generalQuestion: 'General question',
    generalDescription: 'General description',
    questionType: 'Type',
    createdOn: 'Created on',
    lastUpdated: 'Last updated',
    results: 'results',
    headerText: 'Header text',
    footerText: 'Footer text',
    addQuestion: 'Add question',
    editQuestion: 'Edit question',
    addSurvey: 'Add Survey',
    editSurvey: 'Edit Survey',
    addingInformation: 'adding information',
    typeAnswer: 'Type answer',
    answer: 'Answer',
    score: 'Score',
    followUp: 'Follow-up',
    addAnswer: 'Add answer',
    followUpQuestion: 'Go to question:',
    content: 'Content',
    makeYourChoice: 'Make your choice',
    campaigns: 'Campaigns',
    campaign: 'Campaign',
    profiles: 'Profiles',
    extras: 'Extras',
    settings: 'Settings',
    zipCode: 'Zip Code',
    birthDate: "BirthDate",
    country: "Country",
    gender: 'Gender',
    education: 'Education',
    language: 'Language',
    languageEnglish: 'Taal EN',
    languageOriginal: 'Taal Original',
    icon: 'Icon',
    abbreviation: 'Abbreviation',
    lists: 'Lists',
    campaignIntroText: 'Campaign introduction text',
    surveyCreatedMessage: 'Survey created!',
    surveyEditedMessage: 'Survey edited!',
    surveyNotCreatedMessage: 'Survey is not created!',
    surveyNotEditedMessage: 'Question can not be deleted if someone already answered the question ',
    campaignCreatedMessage: 'Campaign created!',
    campaignEditedMessage: 'Campaign edited!',
    campaignNotCreatedMessage: 'Campaign is not created!',
    campaignNotEditedMessage: 'Campaign is not edited',
    campaignSentStatusLabel: 'Campaign status',
    campaignSentDateLabel: 'Campaign send date',
    selectSurvey: 'Select survey',
    conditions: 'conditions',
    and: 'AND',
    valid: 'Valid',
    from: "From",
    to: 'To',
    numberOfMatchingProfiles: "Number of matching profiles",
    maxValue: "Maximum value",
    maximaleCharacters: 'Maximal number of characters',
    minValue: "Minimal value",
    steps: "steps",
    updateProfiles: 'Check profiles',
    displayType: 'Display type',
    addOwnOption: 'Add own option',
    displayInDropdown: 'Display in dropdown',
    canHaveMultipleAnswers: 'Can have multiple answers',
    startCampaign: 'Start campaign',
    startSurvey: 'Start survey',
    goToEndOfSurvey: 'Go to end of survey',
    userInputRequired: 'Dit is een open vraag',
    firstName: 'First name',
    lastName: 'Last name',
    role: 'Role',
    name: 'Name',
    languageStatus: 'Language status',
    labelTranslations: 'Label translations',
    endSurvey: 'End survey',
    endCampaign: 'End campaign',
    questionOfSurvey: 'Question 8 ....... 10',
    nextQuestion: 'Next question',
    previousQuestion: 'Previous question',
    editLanguage: 'Edit language',
    usersAndRoles: 'Users & roles',
    languages: 'Languages',
    users: 'Users',
    roles: 'Roles',
    addRoles: 'Add roles',
    addUser: 'Add user',
    emailAlreadyExists: 'Mail already exists',
    postalCodeFormat: 'Postal code format (NNNN-AA = 8152-JJ)',
    sendAt: 'Send at',
    sendNow: 'Send now',
    sendStatusNotSend: 'Not sent',
    sendStatusPartialSent: 'Partially sent',
    sendStatusSent: "Sent", 
    images: "Images (max 5)",
    uploadImage: "Upload image",
    goesToQuestion: 'Goes to question',
    goToCampaignLinkTitle: 'Link text for surey',
    unsubscribeEmailLinkTitle: 'Unsubscribe link text',
    unsubscribeProfileText: 'Unsubscribe profile text',
    unsubscribeProfileTitle: 'Unsubscribe profile title',
    maritalStatus: 'Marital Status',
    income: 'Income',
    smoking: 'Smoking',
    countryOfBirth: 'Country of birth',
    diabetesDuration: 'Diabetes duration',
    age: 'Age',
    year: 'year',
    languageHasBeenUpdated: 'Language has been updated',
    languageHasNotBeenUpdated: 'Language has not been updated',
    userHasBeenUpdated: 'User has been updated',
    userHasNotBeenUpdated: 'User has not been updated',
    userIsCreated: 'User is created',
    userIsNotCreated: 'User is not created',
    roleIsCreated: 'Role is created',
    roleIsNotCreated: 'Role is not created',
    roleHasBeenUpdated: 'Role has been updated',
    roleHasNotBeenUpdated: 'Role has not been updated',
    userIsDeleted: 'User is deleted',
    userIsNotDeleted: 'User is not deleted',
    roleIsDeleted: 'Role is deleted',
    roleIsNotDeleted: 'Role is not deleted',
    user: 'User',
    questionnaireView : 'View survey',
    questionnaireEdit : 'Edit survey',
    questionnaireDelete : 'Delete survey',
    campaignView : 'View campaign',
    campaignEdit : 'Edit campign',
    campaignDelete : 'Delete campaign',
    settingsView : 'View settings',
    settingsEdit : 'Edit settings',
    settingsDelete : 'Delete settings',
    profileView : 'View profile',
    noAccess: 'You do not have access to view this page',
    close: 'Close',
    placeholder: 'Own answer placeholder',
    copy: 'Copy',
    changeOrder: 'Change order',
    genderEnum: {
        0: 'Unknown',
        1: 'Male',
        2: 'Female',
    },
    educationEnum: {
        0: 'Unknown',
        1: 'Primary education',
        2: 'Practical education',
        3: 'Vmbo',
        4: 'Havo',
        5: 'Vwo',
        6: 'Mbo',
        7: 'Hbo',
        8: 'University',
    },
    maritalStatusEnum: {
        0: 'Unknown',
        1: 'Maried',
        2: 'Single',
    },
    smokingEnum: {
        0: 'Unknown',
        1: 'Yes',
        2: 'No',
        3: 'Sometimes',
    },
    statusTypeEnum: {
        0: 'Unknown',
        1: 'Active',
        2: 'Not active',
        3: 'Archive'
    },
    questionTypeEnum: {
        0: 'Unknown',
        1: 'Open',
        2: 'Multiple Choice',
        3: 'Slider'
    },
    incomeEnum: {
        0: 'Unknown',
        1: 'Minimal',
        2: 'Under Average',
        3: 'Average',
        4: 'Between one and two times average',
        5: 'Two times average or more',
    },
    profileOperatorEnum: {
        0: 'Equals',
        1: 'Greater than',
        2: 'Less than'
    },
    columnNamesEnum: {
        0: 'Postal Code',
        1: 'Birth date',
        2: 'Country',
        // 3: 'Language',
        4: 'Gender',
        5: 'Education',
        6: 'Country of birth',
        7: 'Smoking',
        8: 'Income',
        9: 'Marital Status',
        10: 'Diabetes duration',
        11: 'Campaign participation'
    },
    displayTypeEnum: {
        0: 'Unknown',
        1: 'Text',
        2: 'Date',
        3: 'Number',
        4: 'Postalcode',
        5: 'Email',
        6: 'Country'
    },
    activeTypeEnum: {
        0: 'InActive',
        1: 'Active'
    },
    MultipleChoiceValidationText: 'Validationtext Multiplechoice',
    TextValidationText: 'Validationtext no text',
    NoNumberValidationText: 'Validationtext no number',
    NumberTooHighValidationText: 'Validationtext number too high',
    NumberTooLowValidationText: 'Validationtext number too low',
    InvalidEmailValidationText: 'Validationtext invalid email',
    NoEmailValidationText: 'Validationtext no email',
    InvalidPostalCodeValidationText: 'Validationtext invalid postalcode',
    NoPostalCodeValidationText: 'Validationtext no postalcode',
    invalidDateValidationText: 'Validationtext invalid date',
    stringTooLongValidation: 'Validationtext string too long',
    EmailAddress: 'Email address',
    EmailAddressConfirm: 'Confirm email address',
    EmailAddressNotEqualValidation: 'Email is equal',
    ErrorInPostQuestionAnswer: 'An error occured while submitting your answer, please try again',
    ExportLoading: 'Export loading. This can take a moment.',
    Export: 'Export',
    ProfileCampaign: 'Is Profile Campaign',
    Yes: 'Yes',
    No: 'No',
    ProfileLoadingMessage: 'Your answers are being loaded...',
    Copy: 'Copy',
}
